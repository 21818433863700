import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import HomeApp from './Components/Home/Home'; // Assuming HomeApp is the default export
import AboutPage from './Components/About/About';

function App() {
  return (
    <Router>
      <nav>
        <Link to="/">Home</Link>
        <Link to="/about">About</Link>
      </nav>
      <Routes>
        <Route path="/" element={<HomeApp />} />
        <Route path="/about" element={<AboutPage />} />
      </Routes>
    </Router>
  );
}

export default App;
