import React, { useState } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';/*npm install lucide-react*/

import './Belief.css';

export const CoreBeliefs = () => {
    const [currentSlide, setCurrentSlide] = useState(0);

    const beliefs = [
        {
            title: "Our Core Belief",
            content: "Xstream Minds believes core convictions and tenets drive our actions and decisions, guiding us toward success. It reflects our unwavering commitment to what we believe is essential for growth."
        },
        {
            title: "Our Vision",
            content: "To be the leading force in innovative solutions and transformative technology that shapes the future of digital experiences."
        },
        {
            title: "Our Mission",
            content: "To empower businesses and individuals through cutting-edge technology solutions that drive growth and success."
        }
    ];

    const nextSlide = () => {
        setCurrentSlide((prev) => (prev + 1) % beliefs.length);
    };

    const prevSlide = () => {
        setCurrentSlide((prev) => (prev - 1 + beliefs.length) % beliefs.length);
    };

    return (
        <div className="cb-beliefs-wrapper">
            <div className="cb-beliefs-card">
                <button className="cb-nav-button cb-prev" onClick={prevSlide}>
                    <ChevronLeft />
                </button>

                <div className="cb-slide-container">
                    <div className="cb-slide-content">
                        <h2 className="cb-slide-title">{beliefs[currentSlide].title}</h2>
                        <p className="cb-slide-text">
                            {beliefs[currentSlide].content}
                        </p>
                    </div>
                </div>

                <button className="cb-nav-button cb-next" onClick={nextSlide}>
                    <ChevronRight />
                </button>
            </div>

            <div className="cb-cta-button">
                <button>
                    Get Acquainted with Xstream Minds
                </button>
            </div>
        </div>
    );
};
