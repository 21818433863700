import React, { useState } from 'react';
import './Navbar.css';

export const Navbar = () => {
  const [openDropdown, setOpenDropdown] = useState(null);
  const [openSubDropdown, setOpenSubDropdown] = useState(null);
  const [menuActive, setMenuActive] = useState(false); // State to toggle mobile menu

  const toggleDropdown = (dropdown) => {
    setOpenDropdown(openDropdown === dropdown ? null : dropdown);
    setOpenSubDropdown(null); // Close sub-dropdown when switching
  };

  const toggleSubDropdown = (subDropdown) => {
    setOpenSubDropdown(openSubDropdown === subDropdown ? null : subDropdown);
  };

  const toggleMenu = () => {
    setMenuActive(!menuActive); // Toggle mobile menu
    setOpenDropdown(null); // Close any open dropdowns
    setOpenSubDropdown(null); // Close any open sub-dropdowns
  };

  return (
    <nav className="navbar fixed-navbar">
      <div className="navbar-container">
        <div className="navbar-logo">
          <img src="/images/logo.png" alt="Logo" className="logo" />
        </div>

        

        {/* Menu */}
        <ul className={`navbar-menu ${menuActive ? 'active' : ''}`}>
          <li
            className={`menu-item dropdown ${openDropdown === 'who-we-are' ? 'open' : ''}`}
            onMouseEnter={() => !menuActive && toggleDropdown('who-we-are')}
            onMouseLeave={() => !menuActive && toggleDropdown(null)}
            onClick={() => menuActive && toggleDropdown('who-we-are')}
          >
            Who We Are
            <ul className="dropdown-menu">
              <li className="dropdown-item">About</li>
              <li className="dropdown-item">Careers</li>
            </ul>
          </li>
          <li
            className={`menu-item dropdown ${openDropdown === 'services' ? 'open' : ''}`}
            onMouseEnter={() => !menuActive && toggleDropdown('services')}
            onMouseLeave={() => !menuActive && toggleDropdown(null)}
            onClick={() => menuActive && toggleDropdown('services')}
          >
            Services
            <ul className="dropdown-menu">
              <li
                className={`dropdown-item ${openSubDropdown === 'web-dev' ? 'open' : ''}`}
                onMouseEnter={() => !menuActive && toggleSubDropdown('web-dev')}
                onMouseLeave={() => !menuActive && toggleSubDropdown(null)}
                onClick={() => menuActive && toggleSubDropdown('web-dev')}
              >
                Confluent
                <ul className="sub-dropdown-menu">
                  <li className="sub-dropdown-item">Flink</li>
                  <li className="sub-dropdown-item">Apache Kafka</li>
                  <li className="sub-dropdown-item">Kafka Cloud</li>
                </ul>
              </li>
              <li
                className={`dropdown-item ${openSubDropdown === 'mobile-dev' ? 'open' : ''}`}
                onMouseEnter={() => !menuActive && toggleSubDropdown('mobile-dev')}
                onMouseLeave={() => !menuActive && toggleSubDropdown(null)}
                onClick={() => menuActive && toggleSubDropdown('mobile-dev')}
              >
                Mobile Development
                <ul className="sub-dropdown-menu">
                  <li className="sub-dropdown-item">iOS</li>
                  <li className="sub-dropdown-item">Android</li>
                  <li className="sub-dropdown-item">Cross-Platform</li>
                </ul>
              </li>
              {/* <li
                className={`dropdown-item ${openSubDropdown === 'cloud-solutions' ? 'open' : ''}`}
                onMouseEnter={() => !menuActive && toggleSubDropdown('cloud-solutions')}
                onMouseLeave={() => !menuActive && toggleSubDropdown(null)}
                onClick={() => menuActive && toggleSubDropdown('cloud-solutions')}
              >
                Cloud Solutions
                <ul className="sub-dropdown-menu">
                  <li className="sub-dropdown-item">AWS</li>
                  <li className="sub-dropdown-item">Azure</li>
                  <li className="sub-dropdown-item">Google Cloud</li>
                </ul>
              </li> */}
            </ul>
          </li>

          <li
            className={`menu-item dropdown ${openDropdown === 'insights' ? 'open' : ''}`}
            onMouseEnter={() => !menuActive && toggleDropdown('insights')}
            onMouseLeave={() => !menuActive && toggleDropdown(null)}
            onClick={() => menuActive && toggleDropdown('insights')}
          >
            Insights
            <ul className="dropdown-menu">
              <li className="dropdown-item">Blog</li>
              <li className="dropdown-item">Case Studies</li>
              <li className="dropdown-item">Resources</li>
            </ul>
          </li>

          <li className="menu-item">Contact Us</li>
        </ul>

        <button className="navbar-cta">Get in Touch</button>

        {/* Toggler for mobile view */}
        <button
          className="navbar-toggler"
          onClick={toggleMenu}
          aria-label="Toggle navigation"
        >
          &#9776; {/* Hamburger icon */}
        </button>
      </div>
    </nav>
  );
};